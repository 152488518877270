export class AuthMapper {
    public static mapSignInPostRequest(credentials: OLO.DTO.LoginMemberRequest): APIv3.LoginMemberRequest {
        return credentials
            ? {
                Login: credentials.Login,
                Password: credentials.Password,
                LoginType: credentials.LoginType,
                MobilePhoneCountryId: credentials.MobilePhoneCountryId,
            }
            : null;
    }

    public static mapSignInPostResponse(loginJWT: APIv3.JwtTokenResponse): OLO.DTO.JWTokenLogin {
        if (loginJWT) {
            return {
                jwt: {
                    AccessToken: loginJWT.AccessToken,
                    RefreshToken: loginJWT.RefreshToken,
                },
                sessionKey: 'temp-session-key',
            };
        }

        return null;
    }

    public static mapSignUpPostRequest(member: OLO.DTO.MemberModel): APIv3.MemberSignUpRequestModel {
        return member
            ? {
                PartialMemberId: member.PartialMemberId,
                MemberName: member.MemberName,
                MemberSurname: member.MemberSurname,
                MemberGroupId: member.MemberGroupId,
                Email: member.Email,
                MobilePhone: member.MobilePhone,
                MobilePhoneCountryId: member.MobilePhoneCountryId,
                TierId: member.TierId,
                Birthday: member.Birthday,
                SexId: member.SexId,
                Password: member.Password,
                FavoriteLocationId: member.FavoriteLocationId,
                HomeLocationNo: member.HomeLocationNo,
                HomeVenueNo: member.HomeVenueNo,
                ReceivePromoAllowed: member.ReceivePromoAllowed,
                MobilePushNotificationsAllowed: member.MobilePushNotificationsAllowed,
                IsSMSMarketingAllowed: member.IsSMSMarketingAllowed,
                IsEmailValidated: member.IsEmailValidated,
                IsMobileValidated: member.IsMobileValidated,
                IsOnlineRegistered: member.IsOnlineRegistered,
                PostCode: member.PostCode,
                City: member.City,
                MobilePhoneVerificationCode: member.MobilePhoneVerificationCode,
            }
            : null;
    }

    public static mapSignUpMember(member: OLO.DTO.MemberModel): OLO.DTO.MemberModel {
        return member.PartialMemberId
            ? {
                ...member,
                MemberId: member.PartialMemberId,
            }
            : {
                ...member,
            };
    }

    public static mapSignUpPostResponseJWT(loginJWT: APIv3.JwtTokenResponse): OLO.DTO.JWTokenLogin {
        if (loginJWT) {
            return {
                jwt: {
                    AccessToken: loginJWT.AccessToken,
                    RefreshToken: loginJWT.RefreshToken,
                },
                sessionKey: 'temp-session-key',
            };
        }

        return null;
    }

    public static mapSignUpSimpleResponse(signUpSimple: APIv3.AuthSignUpMember.Responses.$201): boolean {
        return !!signUpSimple;
    }

    public static mapVerifyPhoneNumberRequest(phoneNumber: { LoyaltyAppId: number; PhoneNumber: string; PhoneNumberCountryID: number; }): APIv3.PhoneTemporaryCodeRequest {
        return phoneNumber
            ? {
                PhoneNumber: phoneNumber.PhoneNumber,
                LoyaltyAppId: phoneNumber.LoyaltyAppId,
                PhoneNumberCountryID: phoneNumber.PhoneNumberCountryID,
            }
            : null;
    }

    public static mapVerifyPhoneNumberResponse(verifyPhoneNumber: APIv3.AuthCreateTemporaryCode.Responses.$201): boolean {
        return verifyPhoneNumber;
    }

    public static mapVerifyPhoneNumberTokenRequest(
        phoneNumberToken: { Token: string; PhoneNumber: string; },
        PhoneNumberCountryPrefix: number,
    ): APIv3.PhoneTemporaryCodeValidationRequest {
        return phoneNumberToken
            ? {
                PhoneNumber: phoneNumberToken.PhoneNumber,
                Token: phoneNumberToken.Token,
                PhoneNumberCountryPrefix,
            }
            : null;
    }

    public static mapVerifyPhoneNumberTokenResponse(verifyPhoneNumberToken: APIv3.AuthValidateTemporaryCode.Responses.$200): boolean {
        return verifyPhoneNumberToken;
    }

    public static mapDeleteMemberAccountResponse(deleteMemberAccount: APIv3.MembersClearMemberPersonalData.Responses.$200): boolean {
        return deleteMemberAccount;
    }

    public static mapValidateLoginPostRequest(validateMemberLogin: OLO.DTO.ValidateMemberLoginRequest): APIv3.ValidateMemberLoginRequest {
        return validateMemberLogin
            ? {
                Login: validateMemberLogin.Login,
                LoginType: validateMemberLogin.LoginType,
                MobilePhoneCountryId: validateMemberLogin.MobilePhoneCountryId,
            }
            : null;
    }

    public static mapValidateLoginResponse(validateLogin: APIv3.AuthValidateMemberLogin.Responses.$200): OLO.DTO.ValidateMemberLoginResponse {
        return validateLogin
            ? {
                Id: validateLogin.Id,
                IsMobileValidated: validateLogin.IsMobileValidated,
                IsEmailValidated: validateLogin.IsEmailValidated,
                IsOnlineRegistered: validateLogin.IsOnlineRegistered,
            }
            : null;
    }
}

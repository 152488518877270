import { NgModule, Inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router, RouteConfigLoadEnd } from '@angular/router';

import { ConfirmationGuard } from '@shared/core/guards/confirmation.shared.guard';
import { AppModeOrderingGuard } from '@shared/core/guards/app-mode-ordering.shared.guard';
import { UniqueCodeGuard } from '@shared/core/guards/unique-code.shared.guard';
import { DynamicPathsGuard } from '@shared/core/guards/dynamic-paths.shared.guard';
import { SelectivePreloadingStrategyService } from '@shared/core/resolvers/selective-preloading-strategy.shared.service';
import * as Tokens from '@shared/core/tokens';

export const routes: Routes = [
    {
        path: 'order-confirmation-static',
        loadChildren: () => import('./views/OrderConfirmation/types/OrderConfirmationStatic/order-confirmation-static.module').then(m => m.OrderConfirmationStaticModule),
        canActivate: [
            AppModeOrderingGuard,
            ConfirmationGuard
        ],
        data: { isLiveViewType: false }
    },
    {
        path: 'payment-gateway',
        loadChildren: () => import('./views/PaymentGateway/payment-gateway.module').then(m => m.PaymentGatewayModule),
        canActivate: [
            AppModeOrderingGuard
        ],
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./views/SignUp/sign-up.module').then(m => m.SignUpModule),
        canActivate: [
            UniqueCodeGuard,
        ],
    },
    {
        path: '',
        loadChildren: () => import('./views/views.module').then(m => m.ViewsModule),
    },
    {
        path: '**',
        loadChildren: () => import('./views/Error404/error404.module').then(m => m.Error404Module),
        canActivate: [
            DynamicPathsGuard,
        ],
    },
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(routes,
            {
                // preloadingStrategy: QuicklinkStrategy, // Resolvers.SelectivePreloadingStrategyService,
                useHash: Boolean(history.pushState) === false,
                scrollPositionRestoration: 'enabled',
                relativeLinkResolution: 'legacy'
            })
    ],
    exports: [
        RouterModule
    ],
    providers: [
        SelectivePreloadingStrategyService
    ]
})
export class RootRoutesModule {
    private _init: boolean = false;
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _router: Router,
        private _dynamicPathsGuard: DynamicPathsGuard
    ) {
        const venueName = this._config?.venue?.name;
        const foundCollectionTypeUrl = this._config.collectionTypes.some(collectionType => !!collectionType.url);

        if (venueName || foundCollectionTypeUrl) {
            this._router.events.subscribe(async routerEvent => {
                if (routerEvent instanceof RouteConfigLoadEnd) {
                    // Trigger change detection so _loadedConfig is available in router
                    setTimeout(() => {
                        let configIsChanged = null;
                        this._router.config.forEach(root => {
                            if (root.path === '' && (root as any)._loadedConfig) {
                                configIsChanged = false;
                                const foundChild: any = (root as any)._loadedConfig;
                                if (!foundChild) return;
                                if (venueName) {
                                    try {
                                        const foundMainChild = foundChild
                                            .routes?.find(obj => obj.path === '' && obj.children.some(inner => inner.path === 'VENUE_DYNAMIC_URL')) || null;

                                        if (foundMainChild) {
                                            const venueRoute = foundMainChild.children.find(inner => inner.path === 'VENUE_DYNAMIC_URL');
                                            if (venueRoute) {
                                                venueRoute.path = venueName;
                                                venueRoute.data = { ...venueRoute.data, isDynamic: true };
                                                configIsChanged = true;
                                            }
                                        }
                                    } catch (ex) {
                                        console.warn('Unable to set locations dynamic routes.  Try refresh app', ex, foundChild);
                                    }
                                }
                                if (foundCollectionTypeUrl) {
                                    try {
                                        const foundCollectionTypeMainChild = foundChild
                                            .routes?.find(obj => obj.path === '' && obj.children.some(inner => inner.path === 'COLLECTION_TYPE_DYNAMIC_URL')) || null;

                                        if (foundCollectionTypeMainChild) {
                                            const collectionTypeRoute = foundCollectionTypeMainChild.children.find(inner => inner.path === 'COLLECTION_TYPE_DYNAMIC_URL');
                                            if (collectionTypeRoute) {
                                                const collectionTypeUrls = this._config.collectionTypes.reduce((arrUrls, collectionType) => {
                                                    if (collectionType.url) {
                                                        return [...arrUrls, collectionType.url];
                                                    }

                                                    return arrUrls;
                                                }, []);
                                                collectionTypeUrls.forEach(collectionTypeUrl => {
                                                    collectionTypeRoute.path = collectionTypeUrl;
                                                    collectionTypeRoute.data = { ...collectionTypeRoute.data, isDynamic: true, isCollectionTypeUrl: true };
                                                });
                                                configIsChanged = true;
                                            }
                                        }
                                    } catch (ex) {
                                        console.warn('Unable to set locations dynamic routes.  Try refresh app', ex, foundChild);
                                    }
                                }

                            }
                        });
                        this._dynamicPathsGuard.routeUpdate$.next(configIsChanged);
                    }, 1000);
                }
            });
        } else {
            this._dynamicPathsGuard.routeUpdate$.next(false);
        }
    }
}


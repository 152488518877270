import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as Mappers from '@shared/core/mappers';
import * as Services from '@shared/core/services';

import { Observable, of, forkJoin } from 'rxjs';
import { withLatestFrom, map, take, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public readonly apiBaseUrl: string = this.config.api.base;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
        public store: Store<OLO.State>,
        public router: Router,
        public jwtService: Services.JWTService,
        public sessionService: Services.SessionService,
        public modalService: Services.ModalsService,
        public onlineOrdersService: Services.OnlineOrdersService,
    ) {}

    public initSignIn(accountLogin: string, authorizationType: OLO.Enums.LOGIN_TYPE = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN): void {
        this.store.dispatch(actions.MemberAuthorizationInit({ authorizationType, accountLogin }));
        this.store.dispatch(actions.MemberGuestModeSet({ flag: false }));
    }

    public isAuthorized$(): Observable<boolean> {
        return this.jwtService.getCurrentTokens().pipe(
            withLatestFrom(this.store.pipe(select(selectors.isMemberAuthorizedJWT))),
            map(([jwt, stateAuthorized]) => {
                const JWTFailed: boolean = !!jwt === false || (jwt && !!jwt.AccessToken === false) || !!jwt.RefreshToken === false;

                return JWTFailed === false && stateAuthorized === true;
            }),
            catchError(() => {
                console.log('Session expired');

                return [false];
            }),
        );
    }

    public validateSession(): Observable<boolean> {
        return this.jwtService.getCurrentTokens().pipe(
            take(1),
            catchError(() => of(false)),
            map((isValid) => !!isValid),
        );
    }

    public signIn(credentials: OLO.DTO.LoginMemberRequest): Observable<OLO.DTO.JWTokenLogin> {
        const postModel: APIv3.LoginMemberRequest = Mappers.AuthMapper.mapSignInPostRequest(credentials);

        return this.httpClient
            .post<APIv3.JwtTokenResponse>(`${this.apiBaseUrl}/auth/member/login`, postModel)
            .pipe(map((response: APIv3.JwtTokenResponse) => Mappers.AuthMapper.mapSignInPostResponse(response)));
    }

    public signUp(
        model: OLO.DTO.MemberModel,
        existingMember: OLO.DTO.MemberModel,
        overwriteProps: OLO.DTO.MemberModel = { IsMobileValidated: true },
    ): Observable<OLO.DTO.MemberModel> {
        const _model = model && { ...model } || {};
        let _existingMember = existingMember && { ...existingMember } || {};

        _model.PartialMemberId = _model.MemberId;
        _existingMember.PartialMemberId = _existingMember.MemberId;

        delete _model.MemberId;
        delete _existingMember.MemberId;

        /* Create new user */
        if (!_existingMember || (typeof _existingMember === 'object' && Object.keys(_existingMember).length === 0)) {
            const newUserModel: APIv3.MemberSignUpRequestModel = Mappers.AuthMapper.mapSignUpPostRequest({
                ..._model,
                IsOnlineRegistered: true,
                ...overwriteProps,
            });

            return this.httpClient.post<APIv3.JwtTokenResponse>(`${this.apiBaseUrl}/auth/member/signup`, newUserModel).pipe(
                map((response: APIv3.JwtTokenResponse) => {
                    this.store.dispatch(
                        actions.MemberSignInDataResponseSuccess({
                            password: newUserModel.Password,
                            data: Mappers.AuthMapper.mapSignUpPostResponseJWT(response),
                            withChangeState: false
                        }),
                    );

                    return response ? Mappers.AuthMapper.mapSignUpMember(newUserModel) : null;
                }),
            );
        }

        //
        //  Safe merge new model with old model.
        //  Make sure new member model without values
        //  won't overwrite existing defined values
        //
        _existingMember = Object.keys(_model).reduce((acc, key) => {
            const newVal = _model[key];
            const existingVal = _existingMember[key];

            if ((newVal === null || newVal === undefined) && existingVal !== null && existingVal !== undefined) {
                return {
                    ...acc,
                    [key]: existingVal
                };
            }

            return acc;
        }, {
            ..._existingMember,
            ..._model,
        } as OLO.DTO.MemberModel);

        if (_existingMember.IsOnlineRegistered === false) {
            _existingMember.IsEmailValidated = false;
        }

        _existingMember.IsOnlineRegistered = true;

        _existingMember = {
            ..._existingMember,
            ...overwriteProps,
        };

        const requestModel: APIv3.MemberSignUpRequestModel = Mappers.AuthMapper.mapSignUpPostRequest(_existingMember);

        return this.httpClient
            .post<APIv3.JwtTokenResponse>(`${this.apiBaseUrl}/auth/member/signup`, requestModel)
            .pipe(
                map((response: APIv3.JwtTokenResponse) => {
                    this.store.dispatch(
                        actions.MemberSignInDataResponseSuccess({ password: _existingMember.Password,
                            data: Mappers.AuthMapper.mapSignUpPostResponseJWT(response), withChangeState: false })
                    );

                    return (response) ? Mappers.AuthMapper.mapSignUpMember(_existingMember) : null;
                })
            );
    }

    public verifyPhoneNumber(PhoneNumber: string, PhoneNumberCountryID: number, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv3.PhoneTemporaryCodeRequest = Mappers.AuthMapper.mapVerifyPhoneNumberRequest({
            LoyaltyAppId,
            PhoneNumber,
            PhoneNumberCountryID,
        });

        return this.httpClient
            .post<APIv3.AuthCreateTemporaryCode.Responses.$201>(`${this.apiBaseUrl}/auth/temporaryCode`, postModel)
            .pipe(map((response: APIv3.AuthCreateTemporaryCode.Responses.$201) => Mappers.AuthMapper.mapVerifyPhoneNumberResponse(response)));
    }

    public verifyPhoneNumberToken(PhoneNumber: string, Token: string, PhoneNumberCountryPrefix: number): Observable<boolean> {
        const postModel: APIv3.PhoneTemporaryCodeValidationRequest = Mappers.AuthMapper.mapVerifyPhoneNumberTokenRequest(
            {
                Token,
                PhoneNumber,
            },
            PhoneNumberCountryPrefix,
        );

        return this.httpClient
            .put<APIv3.AuthValidateTemporaryCode.Responses.$200>(`${this.apiBaseUrl}/auth/temporaryCode`, postModel)
            .pipe(map((response: APIv3.AuthValidateTemporaryCode.Responses.$200) => Mappers.AuthMapper.mapVerifyPhoneNumberTokenResponse(response)));
    }

    /** Preserves member data in state, but sets proper flags to false, and removes session data */
    public async softSignOut(): Promise<boolean> {
        this.sessionService.removeSession();
        this.jwtService.clearTokens();

        this.store.dispatch(actions.OnlineOrderClearSavedOrderData());
        this.store.dispatch(actions.HistoryOrdersReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.MemberAuthorizationSetFlag({ flag: false }));

        return true;
    }

    public memberResetStateAndNavigateTo(redirect: boolean | string): Promise<boolean> {
        this.store.dispatch(actions.MemberStateReset());
        this.store.dispatch(actions.MemberGuestModeSet({ flag: true }));

        Utils.Storage.remove(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS);

        if (redirect) {
            if (redirect === true) return this.router.navigate(['/']);

            return this.router.navigate([redirect]);
        }
    }

    public async signOut(redirect: boolean | string = '/'): Promise<boolean> {
        if (this.config.cart.clearOnLogoutMode === OLO.Enums.CART_LOGOUT_MODE.ASK_CLEAR_ON_LOGOUT) {
            this.store
                .select(selectors.getCartTotalQuantity)
                .pipe(take(1))
                .subscribe(async (totalItemsCart) => {
                    await this.softSignOut();
                    if (totalItemsCart > 0) {
                        this.modalService.show({
                            type: 'cart-clear',
                            params: {
                                redirect,
                            },
                        });
                    } else {
                        this.memberResetStateAndNavigateTo(redirect);
                    }
                });
        } else if (this.config.cart.clearOnLogoutMode === OLO.Enums.CART_LOGOUT_MODE.CLEAR_ON_LOGOUT) {
            await this.softSignOut();
            this.store
                .select(selectors.getActiveVoucher)
                .pipe(take(1))
                .subscribe((voucher) => {
                    if (voucher) {
                        this.store.dispatch(actions.OnlineOrderRemoveVoucherRequest());
                    }
                    this.store.dispatch(actions.CartReset());
                });
        }

        if (this.config.cart.clearOnLogoutMode !== OLO.Enums.CART_LOGOUT_MODE.ASK_CLEAR_ON_LOGOUT) {
            await this.softSignOut();
            this.memberResetStateAndNavigateTo(redirect);
        }

        return true;
    }

    public deleteMemberAccount(): Observable<boolean> {
        return this.httpClient
            .delete<APIv3.MembersClearMemberPersonalData.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/personalData`)
            .pipe(map((response: APIv3.MembersClearMemberPersonalData.Responses.$200) => Mappers.AuthMapper.mapDeleteMemberAccountResponse(response)));
    }

    public signUpSimple(member: OLO.DTO.MemberModel): Observable<boolean> {
        const requestModel: APIv3.MemberSignUpRequestModel = Mappers.AuthMapper.mapSignUpPostRequest(member);

        return this.httpClient
            .post<APIv3.AuthSignUpMember.Responses.$201>(`${this.apiBaseUrl}/auth/member/signup`, requestModel)
            .pipe(map((response: APIv3.AuthSignUpMember.Responses.$201) => Mappers.AuthMapper.mapSignUpSimpleResponse(response)));
    }

    public validateLogin(params: OLO.DTO.ValidateMemberLoginRequest = {}): Observable<OLO.DTO.ValidateMemberLoginResponse> {
        const requestModel: APIv3.ValidateMemberLoginRequest = Mappers.AuthMapper.mapValidateLoginPostRequest(params);

        return this.httpClient
            .post<APIv3.AuthValidateMemberLogin.Responses.$200>(`${this.config.api.base}/auth/member/validateLogin`, requestModel)
            .pipe(map((response: APIv3.AuthValidateMemberLogin.Responses.$200) => Mappers.AuthMapper.mapValidateLoginResponse(response)));
    }

    public validateEmailWithMemberCardNumber(email: string, memberCardNo: string): Observable<OLO.DTO.ValidateMemberLoginResponse | false> {
        return forkJoin(
            this.validateLogin({
                Login: email,
                LoginType: OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN,
            }),
            this.validateLogin({
                Login: memberCardNo,
                LoginType: OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN,
            }),
        ).pipe(
            map(([emailResponse, memberCardResponse]) => {
                if (!emailResponse || !memberCardResponse) {
                    throw new Error('Unable to match logins - invalid payload');
                }
                const match = Object.keys(emailResponse).reduce((acc, key) => {
                    if (!acc) return acc;

                    return emailResponse[key] === memberCardResponse[key] && emailResponse[key] !== null && memberCardResponse[key] !== null;
                }, true);
                if (!match) return false;

                return emailResponse;
            }),
        );
    }
}

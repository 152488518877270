import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './history-orders.actions';

const initialState: OLO.State.HistoryOrders = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    isDownloadingMore: false,
    hasSucceededMore: false,
    hasFailedMore: false,
    downloadedDate: null,
    orders: [],
};

export const historyOrdersReducerFn = createReducer(
    initialState,
    on(actions.HistoryOrdersReset, () => ({
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        isDownloadingMore: false,
        hasSucceededMore: false,
        hasFailedMore: false,
        downloadedDate: null,
        orders: [],
    })),
    on(actions.HistoryOrdersRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.HistoryOrdersSuccessRequest, (state, action) => {
        const mapIds = action.payload.map((order) => order.Id);
        const oldToUpdate = [];
        const newToAdd = [];
        for (let i = 0, j = mapIds.length; i < j; i++) {
            if (state.orders.find((order) => order.OrderId === mapIds[i])) {
                oldToUpdate.push(mapIds[i]);
            } else {
                newToAdd.push(mapIds[i]);
            }
        }

        return {
            ...state,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: new Date().getTime(),
            orders: [
                ...state.orders.map((order) => {
                    if (oldToUpdate.includes(order.OrderId)) {
                        return {
                            ...order,
                            data: action.payload.find((obj) => obj.Id === order.OrderId),
                            isDownloading: false,
                            hasSucceeded: true,
                            hasFailed: false,
                            downloadedDate: new Date().getTime(),
                            OrderId: order.OrderId,
                        };
                    }

                    return order;
                }),
                ...newToAdd.map((id) => ({
                    OrderId: id,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload.find((obj) => obj.Id === id),
                })),
            ],
        };
    }),
    on(actions.HistoryOrdersErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),

    on(actions.HistoryOrdersLoadMoreRequest, (state) => ({
        ...state,
        isDownloadingMore: true,
        hasSucceededMore: false,
        hasFailedMore: false,
    })),
    on(actions.HistoryOrdersLoadMoreSuccessRequest, (state, action) => {
        const mapIds = action.payload.map((order) => order.Id);
        const oldToUpdate = [];
        const newToAdd = [];
        for (let i = 0, j = mapIds.length; i < j; i++) {
            if (state.orders.find((order) => order.OrderId === mapIds[i])) {
                oldToUpdate.push(mapIds[i]);
            } else {
                newToAdd.push(mapIds[i]);
            }
        }

        return {
            ...state,
            isDownloadingMore: false,
            hasSucceededMore: true,
            hasFailedMore: false,
            downloadedDate: new Date().getTime(),
            orders: [
                ...state.orders.map((order) => {
                    if (oldToUpdate.includes(order.OrderId)) {
                        return {
                            ...order,
                            data: action.payload.find((obj) => obj.Id === order.OrderId),
                            isDownloading: false,
                            hasSucceeded: true,
                            hasFailed: false,
                            downloadedDate: new Date().getTime(),
                            OrderId: order.OrderId,
                        };
                    }

                    return order;
                }),
                ...newToAdd.map((id) => ({
                    OrderId: id,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload.find((obj) => obj.Id === id),
                })),
            ],
        };
    }),
    on(actions.HistoryOrdersLoadMoreErrorRequest, (state) => ({
        ...state,
        isDownloadingMore: false,
        hasSucceededMore: false,
        hasFailedMore: true,
    })),

    /* Single */
    on(actions.HistoryOrderRequest, (state, action) => {
        const foundOrder: OLO.State.HistoryOrders.OnlineOrderItemObj = state.orders.find((order) => order.OrderId === action.orderId);

        if (!foundOrder) {
            return {
                ...state,
                orders: [
                    ...state.orders,
                    {
                        OrderId: action.orderId,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                        downloadedDate: null,
                        data: null,
                    },
                ],
            };
        }

        return {
            ...state,
            orders: state.orders.map((order) => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return order;
            }),
        };
    }),
    on(actions.HistoryOrderSuccessRequest, (state, action) => ({
        ...state,
        orders: state.orders.map((order) => {
            if (order.OrderId === action.orderId) {
                return {
                    ...order,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload,
                };
            }

            return order;
        }),
    })),
    on(actions.HistoryOrderErrorRequest, (state, action) => ({
        ...state,
        orders: state.orders.map((order) => {
            if (order.OrderId === action.orderId) {
                return {
                    ...order,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return order;
        }),
    })),
    on(
        actions.HistoryOrderStatusRequest,
        (state, action) => ({
            ...state,
            orders: state.orders.map(order => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return order;
            })
        })
    ),
    on(
        actions.HistoryOrderStatusSuccessRequest,
        (state, action) => ({
            ...state,
            orders: state.orders.map(order => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        downloadedDate: new Date().getTime(),
                        data: {
                            ...order.data,
                            Status: action.payload
                        }
                    };
                }

                return order;
            })
        })
    ),
    on(
        actions.HistoryOrderStatusErrorRequest,
        (state, action) => ({
            ...state,
            orders: state.orders.map(order => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }

                return order;
            })
        })
    ),
    on(
        actions.HistoryOrderLoadFromCache,
        (state, action) => {
            const foundOrder = state.orders.find(order => order.OrderId === action.order.Id);

            if (!foundOrder) {
                return {
                    ...state,
                    orders: [
                        ...state.orders,
                        {
                            OrderId: action.order.Id,
                            isDownloading: false,
                            hasSucceeded: true,
                            hasFailed: false,
                            downloadedDate: new Date().getTime(),
                            data: action.order,
                        }
                    ]
                };
            }

            return {
                ...state
            };
        }
    ),
);

export function historyOrdersReducer(state: OLO.State.HistoryOrders | undefined, action: Action) {
    return historyOrdersReducerFn(state, action);
}
